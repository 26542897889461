<template>
  <div class="nk-block-head nk-block-head-lg">
    <div class="nk-block-between">
      <div class="nk-block-head-content">
        <h4 class="nk-block-title mb-0">{{ title }}</h4>
        <div class="nk-block-des">
          <p class="mb-0">Manage your Taxes Rates</p>
        </div>
      </div>
      <ul class="nk-block-tools g-0">
        <li class="nk-block-tools-opt">
          <a
            href="#"
            title="Add new item"
            class="btn btn-white btn-dim btn-outline-light"
            @click.prevent="AddTax = true"
          >
            <em class="dd-indc icon ni ni-edit"></em>
            <span class="d-inline">Add a new tax</span>
            <em class="dd-indc w3-text-theme icon ni ni-chevron-right"></em>
          </a>
          <AddTax
            :toggle-by="AddTax"
            @oncancel="AddTax = false"
            @onsave="AddTax = false"
            title="Add new tax"
          ></AddTax>
        </li>
      </ul>
    </div>
  </div>
  <ListTax id="taxList" :items="Taxes" />
</template>
<script>
import { ref, computed } from "vue";
import ListTax from "@/components/ui/settings/taxes/ListTax.vue";
import AddTax from "@/components/ui/settings/taxes/AddTax.vue";
import { Util } from "@/helpers/utilities";

import { useStore } from "vuex";
export default {
  components: {
    ListTax,
    AddTax,
  },
  props: {
    type: {
      type: String,
      default: "",
    },
  },
  setup() {
    const title = ref("Taxes");
    const store = useStore();
    const Settings = computed(() => store.getters.settings);
    const AddTax = ref(false);
    const Taxes = computed(() => store.getters["taxes/taxes"]);

    return {
      title,
      Settings,
      Taxes,
      AddTax,
      Util,
    };
  },
};
</script>

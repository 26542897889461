<template>
  <template v-if="!Util.isEmpty(Taxes)">
    <DataTable :id="ID" class="table-hover table-md" :options="Options">
      <thead>
        <tr class="nk-tb-item nk-tb-head size12">
          <th class="w3-hover-light-grey nk-tb-col py-4 tb-col-lg"></th>
          <th class="w3-hover-light-grey nk-tb-col py-4 tb-col-lg">NAME</th>
          <th class="w3-hover-light-grey nk-tb-col py-4">ABBR</th>
          <th class="w3-hover-light-grey nk-tb-col py-4">RATE</th>
          <th class="nk-tb-col py-4 tb-col-md">ID</th>
          <th class="nk-tb-col py-4 nk-tb-col-tools text-right"></th>
        </tr>
      </thead>
      <tbody>
        <tr class="nk-tb-item size14" v-for="(tax, i) in Taxes" :key="tax.uuid">
          <td class="nk-tb-col" width="1%">
            {{ i + 1 }}
          </td>
          <td class="nk-tb-col tb-col-lg">
            {{ tax.tax_name + " " }}
          </td>
          <td class="nk-tb-col">{{ tax.tax_abbr }}</td>
          <td class="nk-tb-col" nowrap>{{ tax.tax_rate }}%</td>
          <td class="nk-tb-col tb-col-md">
            <span
              title="Tax ID is displayed on invoices"
              v-if="tax.tax_id && tax.show_tax_id"
              ><i class="fas fa-eye size14"></i
            ></span>
            {{ tax.tax_id }}
          </td>
          <td class="nk-tb-col nk-tb-col-tools">
            <ul class="nk-tb-actions gx-1">
              <li>
                <div class="drodown">
                  <a
                    href="#"
                    class="dropdown-toggle btn btn-icon btn-trigger"
                    data-toggle="dropdown"
                    ><em class="icon ni ni-more-h"></em
                  ></a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <ul class="link-list-opt no-bdr">
                      <li>
                        <a
                          href="#"
                          @click.prevent="QuickEdit(tax.uuid)"
                          class="cursor"
                        >
                          <em class="icon ni ni-edit"></em>
                          <span>Edit Tax</span></a
                        >
                      </li>
                      <li>
                        <a
                          title="Duplicate"
                          href="#"
                          @click.prevent="Duplicate(tax.uuid)"
                        >
                          <em class="dd-indc icon ni ni-copy"></em>
                          <span>Duplicate Tax</span>
                        </a>
                      </li>
                      <li>
                        <a title="Disable" href="#">
                          <em class="dd-indc icon ni ni-copy"></em>
                          <span>Disable Tax</span>
                        </a>
                      </li>
                      <li>
                        <a
                          title="Delete Item"
                          @click.prevent="DeleteItem(tax.uuid)"
                          href="#"
                        >
                          <em class="icon fas fa-trash"></em>
                          <span>Delete Tax</span></a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </DataTable>
  </template>
  <div v-else class="w3-center">
    <img src="/images/item-not-found.png" class="mb-4" />
    <h4>No Item was found</h4>
    <a
      href="#"
      title="Add a new tax"
      class="btn btn-white btn-dim btn-outline-light"
      @click.prevent="addTax = true"
    >
      <em class="dd-indc icon ni ni-edit"></em>
      <span class="d-none d-md-inline">Add a new tax</span>
      <em class="dd-indc w3-text-theme icon ni ni-chevron-right"></em>
    </a>
    <AddTax
      :toggle-by="addTax"
      @oncancel="addTax = false"
      @onsave="addTax = false"
      title="Add new tax"
    ></AddTax>
  </div>
  <Aside
    :toggle-by="DoEdit"
    @oncancel="OnCancel"
    cancel-text="Cancel"
    width="600px"
  >
    <UpdateTax
      v-if="DoEdit"
      :tax="Tax"
      @onsave="OnSave"
      @oncancel="OnCancel"
      cancel-text="Cancel"
    />
  </Aside>
</template>
<script>
import { ref, toRef } from "vue";
import { useStore } from "vuex";
import { v4 as uuidv4 } from "uuid";
import { Util } from "@/helpers/utilities";
import DataTable from "@/components/ui/DataTable.vue";
import Aside from "@/components/ui/Aside.vue";
import UpdateTax from "@/components/ui/settings/taxes/UpdateTax.vue";
import AddTax from "@/components/ui/settings/taxes/AddTax.vue";

export default {
  components: {
    DataTable,
    Aside,
    AddTax,
    UpdateTax,
  },
  props: {
    id: {
      type: String,
    },
    items: {
      type: Array,
    },
  },

  setup(props) {
    const store = useStore();
    const ID = props.id ? props.id : uuidv4();
    const Taxes = toRef(props, "items");
    const Options = {
      pageLength: 25,
      responsive: false,
      order: [[0, "asc"]],
      aoColumnDefs: [
        {
          bSortable: false,
          aTargets: [-1, -2],
        },
      ],
    };
    const DeleteItem = async (uuid) => {
      const response = await store.dispatch("taxes/deleteTax", uuid);
      if (response)
        // Util.Success("Deleted successfully");
        Util.notify({ message: "Tax deleted successfully", status: "success" });
    };
    const DoEdit = ref(false);
    const addTax = ref(false);
    const Tax = ref({});
    const QuickEdit = async (uuid) => {
      DoEdit.value = false;
      console.log(uuid);
      Tax.value = await store.dispatch("taxes/getTax", uuid);
      if (!Util.isEmpty(Tax.value)) DoEdit.value = true;
    };
    const OnCancel = () => {
      Tax.value = {};
      DoEdit.value = false;
    };
    const OnSave = () => {
      OnCancel();
    };
    const Duplicate = async (uuid) => {
      const _tax = await store.dispatch("taxes/duplicateTax", uuid);
      if (!Util.isEmpty(_tax)) {
        delete _tax.created_at;
        delete _tax.created_by;
        delete _tax.id;
        delete _tax.updated_at;
        delete _tax.updated_by;
        _tax.uuid = "";
        Tax.value = _tax;
        DoEdit.value = true;
      }
    };
    return {
      store,
      ID,
      QuickEdit,
      Duplicate,
      OnCancel,
      OnSave,
      DeleteItem,
      Taxes,
      Tax,
      Util,
      Options,
      DoEdit,
      addTax,
    };
  },
};
</script>